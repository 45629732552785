import React, { useEffect, useState } from 'react'
import CtaSection from './Sections/CtaSection'
import DynamicTable from './Sections/DynamicTable'
import Hero from './Sections/Hero'
import Services from './Sections/Services'
import ImgSlide from './Sections/Slider'
import Steps from './Sections/Steps'
import TredingSlide from './Sections/TredingSlide'
import Market from './Sections/market'
import './style.css'

function Home() {
  const [currencyData,setCurrencyData] = useState([])
  const [load,setLoad] = useState(false)
  const [error,setError] = useState(false)
  let url = 'https://unilayerapi.herokuapp.com'
  const getCurrencyData = async () => {
    try {
      setLoad(true)
      const response = await fetch(`${url}/api/getCryptoData`,{method:"GET"})
      const mainResponse = await response.json();
      if(!mainResponse?.success){
        setError(mainResponse?.error)
        setLoad(false)
        return
      }else {
        setCurrencyData(mainResponse?.result)
        setLoad(false)
        return
      }
    } catch (error) {
      setLoad(false)
      setError('Something went wrong')
    }
  }
  useEffect(() => {
    getCurrencyData()
  },[])
  return (
    <div id="home-wrapper">
      <Hero />
      <TredingSlide currencyData={currencyData} load={load} error={error} />
      <Services />
      <DynamicTable  currencyData={currencyData} load={load} error={error} />
      <ImgSlide />
      <Steps />
      <Market />
      <CtaSection />
    </div>
  )
}

export default Home
