import React from 'react'
import ServiceCard from '../../../components/ServicesCard'
import { serviceList } from '../../../constant'

function Services() {
  return (
    <section className="services">
      <div className="container">
        <div className="row">
          <div className="serivce-cards">
            {serviceList.map((item, index) => {
              return <ServiceCard item={item} key={index} />
            })}
          </div>
          <div className="service-content">
            <h2 className="h2heading">
              Unleash <span>Your Trading</span> Potential with Unilayer
            </h2>
            <p className="para">
              Since 2020, Unilayer has been delivering powerful decentralized trading tools for
              crypto enthusiasts and professionals alike. With numerous options to choose from, you
              can trade any decentralized asset on multiple chains with ease. Join Unilayer and
              experience the freedom of decentralized trading, now and always.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
