import Bitcoin from '../assets/images/bitcoin.svg'
import Ethereum from '../assets/images/Ethereum.svg'
import USDT from '../assets/images/Usdt.svg'
import BNB from '../assets/images/BNB.svg'
import DownGraph from '../assets/images/downGraph.svg'
import UPGraph from '../assets/images/upGraph.svg'
import Basic from '../assets/images/Basic.svg'
import Pro from '../assets/images/Pro.svg'
import DEX from '../assets/images/DEX.svg'
import Launchpad from '../assets/images/Launchpad.svg'
import Fund from '../assets/images/fund.svg'
import Prices from '../assets/images/Prices.svg'
import Dollar from '../assets/images/Dollar.svg'
import Logo from '../assets/images/Logo.svg'
import Discord from '../assets/images/Discord.svg'
import Twitter from '../assets/images/twitter.svg'
import telegram from '../assets/images/telegram.svg'
import Slide1 from '../assets/images/slide1.svg'
import Slide2 from '../assets/images/fluxnetbanner2.jpg'
import Slide3 from '../assets/images/fluxnetbanner3.jpg'
import Kucoin from '../assets/images/kucoin.svg'
import Bitmart from '../assets/images/bitmart.svg'
import Latoken from '../assets/images/lotaken.svg'
import GateIo from '../assets/images/Gate_io.svg'
import Pancakeswap from '../assets/images/pancakeswap.svg'
import Uniswap from '../assets/images/Uniswap.svg'

export const imageSlider = [
  // {
  //   image: Slide1,
  //   altText: 'Slide_1'
  // },
  {
    image: Slide2,
    altText: 'Fluxnet',
    url: 'https://www.fluxnet.technology'
  },
  {
    image: Slide3,
    altText: 'Slide_1'
  }
]

export const CurrencyCions = [
  {
    coinImage: Bitcoin,
    graph: DownGraph,
    title: 'Bitcoin',
    currency: 'BTC',
    price: '$24,869.30',
    percent: '-0.75%',
    volume: '28,888.18M',
    color: '#EF4444'
  },
  {
    coinImage: Ethereum,
    graph: DownGraph,
    title: 'Ethereum',
    currency: 'ETH',
    price: '$1,697.23',
    percent: '-1.95%',
    volume: '8,355.84M',
    color: '#EF4444'
  },
  {
    coinImage: USDT,
    graph: UPGraph,
    title: 'USDT',
    currency: 'TetherUS',
    price: '$1.00',
    percent: '+0.05%',
    volume: '38,423.38M',
    color: '#22C55E'
  },
  {
    coinImage: BNB,
    graph: UPGraph,
    title: 'BNB',
    currency: 'BNB',
    price: '$309.90',
    percent: '-1.46%',
    volume: '$5,100,270',
    color: '#22C55E'
  },
  {
    coinImage: Bitcoin,
    graph: DownGraph,
    title: 'Bitcoin',
    currency: 'BTC',
    price: '$24,869.30',
    percent: '-0.75%',
    volume: '28,888.18M',
    color: '#EF4444'
  },
  {
    coinImage: Ethereum,
    graph: DownGraph,
    title: 'Ethereum',
    currency: 'ETH',
    price: '$1,697.23',
    percent: '-1.95%',
    volume: '8,355.84M',
    color: '#EF4444'
  },
  {
    coinImage: USDT,
    graph: UPGraph,
    title: 'USDT',
    currency: 'TetherUS',
    price: '$1.00',
    percent: '+0.05%',
    volume: '38,423.38M',
    color: '#22C55E'
  },
  {
    coinImage: BNB,
    graph: UPGraph,
    title: 'BNB',
    currency: 'BNB',
    price: '$309.90',
    percent: '-1.46%',
    volume: '$5,100,270',
    color: '#22C55E'
  },
  {
    coinImage: Bitcoin,
    graph: DownGraph,
    title: 'Bitcoin',
    currency: 'BTC',
    price: '$24,869.30',
    percent: '-0.75%',
    volume: '28,888.18M',
    color: '#EF4444'
  },
  {
    coinImage: Ethereum,
    graph: DownGraph,
    title: 'Ethereum',
    currency: 'ETH',
    price: '$1,697.23',
    percent: '-1.95%',
    volume: '8,355.84M',
    color: '#EF4444'
  },
  {
    coinImage: USDT,
    graph: UPGraph,
    title: 'USDT',
    currency: 'TetherUS',
    price: '$1.00',
    percent: '+0.05%',
    volume: '38,423.38M',
    color: '#22C55E'
  },
  {
    coinImage: BNB,
    graph: UPGraph,
    title: 'BNB',
    currency: 'BNB',
    price: '$309.90',
    percent: '-1.46%',
    volume: '$5,100,270',
    color: '#22C55E'
  },
  {
    coinImage: Bitcoin,
    graph: DownGraph,
    title: 'Bitcoin',
    currency: 'BTC',
    price: '$24,869.30',
    percent: '-0.75%',
    volume: '28,888.18M',
    color: '#EF4444'
  },
  {
    coinImage: Ethereum,
    graph: DownGraph,
    title: 'Ethereum',
    currency: 'ETH',
    price: '$1,697.23',
    percent: '-1.95%',
    volume: '8,355.84M',
    color: '#EF4444'
  },
  {
    coinImage: USDT,
    graph: UPGraph,
    title: 'USDT',
    currency: 'TetherUS',
    price: '$1.00',
    percent: '+0.05%',
    volume: '38,423.38M',
    color: '#22C55E'
  },
  {
    coinImage: BNB,
    graph: UPGraph,
    title: 'BNB',
    currency: 'BNB',
    price: '$309.90',
    percent: '-1.46%',
    volume: '$5,100,270',
    color: '#22C55E'
  }
]

export const serviceList = [
  {
    image: Basic,
    title: 'Basic Mode',
    subTitle: 'FOR beginners',
    desc: 'A simple, user-friendly interface for beginner traders.',
    btnText: 'Trade Now',
    btnLink: 'https://unilayer.app/basic-mode/0xb1a824a6caf1f789aa7ca1072e36e83cd62ba3ee'
  },
  {
    image: Pro,
    title: 'Pro Mode',
    subTitle: 'advanced trading',
    desc: 'Advanced trading tools and features for experienced traders.',
    btnText: 'Trade Now',
    btnLink: 'https://unilayer.app/pro-mode/0xb1a824a6caf1f789aa7ca1072e36e83cd62ba3ee'
  },
  {
    image: DEX,
    title: 'Dex Aggregator',
    subTitle: 'trending',
    desc: 'Find the best price on 13+ exchanges.',
    btnText: 'Trade Now',
    btnLink: 'https://unilayer.app/aggregator/home'
  },
  {
    image: Launchpad,
    title: 'Launchpad',
    subTitle: 'invest',
    desc: 'Get early access to potentially lucrative opportunities.',
    btnText: 'Trade Now',
    btnLink: '/'
  }
]

export const cryptoExchange = {
  title: `Trade Any Asset  `,
  title2: `with a Wide Range of Tools`,
  subtitle: 'Trade with confidence',
  steps: [
    {
      image: Fund,
      title: 'Control over your own funds',
      desc: 'Unilayer offers you a decentralized trading platform that lets you have control over your own funds. ',
      className: 'Step-card'
    },
    {
      image: Prices,
      title: 'Always the best prices',
      desc: 'Use our DEX aggregator to always find the best prices from 13+ exchanges.',
      className: 'Step-card'
    },
    {
      image: Dollar,
      title: 'Raise money for your startup',
      desc: 'Unilayer is offering a fully decentralized fund raise platform for projects and investors.',
      className: 'Step-card'
    }
  ]
}

export const FooterContent = {
  image: Logo,
  des: `Unilayer introduces much-needed trade automation tools and new token economics on top of the fundamental building blocks of Uniswap and its on-chain infrastructure.`,
  copyRight: 'Copyright © 2020 Unilayer. All rights reserved.',
  FooterLinks: [
    {
      title: 'DeFi Products',
      Links: [
        {
          text: 'Prices',
          link: '/'
        },
        {
          text: 'Basic Mode',
          link: 'https://unilayer.app/basic-mode/0xb1a824a6caf1f789aa7ca1072e36e83cd62ba3ee'
        },
        {
          text: 'Aggregator',
          link: 'https://unilayer.app/aggregator/home'
        },
        {
          text: 'Staking',
          link: 'https://unilayer.app/stake/staking'
        },
        {
          text: 'Farming',
          link: 'https://farm.unilayer.app/farm/LAYERXETHPool'
        },
        {
          text: 'Exchange Base',
          link: '/'
        },
        {
          text: 'Explorer',
          link: 'https://layerscan.io/'
        },
        {
          text: 'Launchpad',
          link: '/'
        },
        {
          text: 'Perpetuals Base',
          link: '/'
        }
      ]
    },
    {
      title: 'Company',
      Links: [
        {
          text: 'Makiswap',
          link: 'https://www.makiswap.com'
        },
        {
          text: 'GAIA Everworld',
          link: 'https://www.gaiaworld.com'
        },
        {
          text: 'Unilayer Card',
          link: '/'
        },
        {
          text: 'Unilayer Exchange',
          link: '/'
        },
        {
          text: 'Layer Chain',
          link: '/'
        },
        {
          text: 'Centralized Application',
          link: '/'
        }
      ]
    },
    {
      title: 'Resources',
      Links: [
        {
          text: 'Contact Us',
          link: 'mailto:mailto:contact@unilayer.app'
        }
      ]
    },
    {
      title: 'Community',
      Links: [
        {
          image: Discord,
          text: 'Discord',
          url: 'https://discord.com/invite/BV5y3dd'
        },
        {
          image: Twitter,
          text: 'Twitter',
          url: 'https://twitter.com/unilayer_'
        },
        {
          image: telegram,
          text: 'Telegram',
          url: 'https://t.me/Unilayer'
        }
      ]
    }
  ]
}

export const TableData = {
  name: 'Name',
  LastPrice: 'Last Price',
  Changes: '24H Change',
  Volume: '24H Volume',
  Chart: '7d Chart'
}
export const mainMenu = [
  {
    title: 'Prices',
    Link: '/'
  },
  {
    title: 'Product',
    MegaList: [
      {
        title: 'DeFi',

        menus: [
          {
            title: 'Basic Mode',
            Content: 'A simplified interface for beginners.',
            link: 'https://unilayer.app/basic-mode/0xb1a824a6caf1f789aa7ca1072e36e83cd62ba3ee'
          },
          {
            title: 'Pro Mode',
            Content: 'An advanced interface for experienced traders.',
            link: 'https://unilayer.app/pro-mode/0xb1a824a6caf1f789aa7ca1072e36e83cd62ba3ee'
          },
          {
            title: 'Aggregator',
            Content: 'Platform unifying liquidity across DeFi protocols.',
            link: 'https://unilayer.app/aggregator/home'
          },
          {
            title: 'Staking',
            Content: 'Earn rewards by locking up Unilayer tokens.',
            link: 'https://unilayer.app/stake/staking'
          },
          {
            title: 'Farming',
            Content: 'Earn rewards by providing liquidity to Unilayer protocol.',
            link: 'https://farm.unilayer.app/farm/LAYERXETHPool'
          },
          {
            title: 'Exchange Base',
            Content: 'Trade, stake, and farm on a top base-chain DEX.',
            link: '/'
          },
          {
            title: 'Explorer',
            Content: 'Browse and track DeFi transactions.',
            link: 'https://layerscan.io/'
          },
          {
            title: 'Launchpad',
            Content: 'Platform for launching new DeFi projects.',
            link: '/'
          },
          {
            title: 'Perpetuals Base',
            Content: 'Decentralized perpetuals on base chain.',
            link: 'https://perp.unilayer.app/#/trade'
          }
        ]
      },
      {
        title: 'Ecosystem',
        menus: [
          {
            title: 'Makiswap',
            Content: 'Pulsechain-powered Decentralized Exchange',
            link: 'https://www.makiswap.com'
          },
          {
            title: 'Gaia Everworld',
            Content: 'Join the epic journey in Gaia Everworld',
            link: 'https://www.gaiaworld.com'
          },
          {
            title: 'Unilayer Card',
            Catagory: 'Upcoming product',
            comingSoon: '#AD1B68',
            link: '/'
          },
          {
            title: 'Unilayer Exchange',
            Catagory: 'Under Development',
            link: '/'
          },
          {
            title: 'Fluxnet',
            Content: 'Innovative Layer 2 scaling with Fluxnet',
            link: 'https://fluxnet.technology'
          },
          {
            title: 'Centralized Application',
            Catagory: 'Under Development',
            link: '/'
          }
        ]
      }
    ]
  }
]
export const MegaMenuList = [
  {
    title: 'Prices',
    Link: '/'
  },
  {
    title: 'DeFi',

    menus: [
      {
        title: 'Basic Mode',
        Content: 'A simplified interface for beginners.',
        link: 'https://unilayer.app/basic-mode/0xb1a824a6caf1f789aa7ca1072e36e83cd62ba3ee'
      },
      {
        title: 'Pro Mode',
        Content: 'An advanced interface for experienced traders.',
        link: 'https://unilayer.app/pro-mode/0xb1a824a6caf1f789aa7ca1072e36e83cd62ba3ee'
      },
      {
        title: 'Aggregator',
        Content: 'Platform unifying liquidity across DeFi protocols.',
        link: 'https://unilayer.app/aggregator/home'
      },
      {
        title: 'Staking',
        Content: 'Earn rewards by locking up Unilayer tokens.',
        link: 'https://unilayer.app/stake/staking'
      },
      {
        title: 'Farming',
        Content: 'Earn rewards by providing liquidity to Unilayer protocol.',
        link: 'https://farm.unilayer.app/farm/LAYERXETHPool'
      },
      {
        title: 'Exchange Base',
        Content: 'Trade, stake, and farm on a top base-chain DEX.',
        link: '/'
      },
      {
        title: 'Explorer',
        Content: 'Browse and track DeFi transactions.',
        link: 'https://layerscan.io/'
      },
      {
        title: 'Launchpad',
        Content: 'Platform for launching new DeFi projects.',
        link: '/'
      },
      {
        title: 'Perpetuals Base',
        Content: 'Decentralized perpetuals on base chain.',
        link: 'https://perp.unilayer.app/#/trade'
      }
    ]
  },
  {
    title: 'Ecosystem',
    menus: [
      {
        title: 'Makiswap',
        Content: 'Pulsechain-powered Decentralized Exchange',
        link: 'https://www.makiswap.com'
      },
      {
        title: 'Gaia Everworld',
        Content: 'Join the epic journey in Gaia Everworld',
        link: 'https://www.gaiaworld.com'
      },
      {
        title: 'Unilayer Card',
        Catagory: 'Upcoming product',
        comingSoon: '#AD1B68',
        link: '/'
      },
      {
        title: 'Unilayer Exchange',
        Catagory: 'Under Development',
        link: '/'
      },
      {
        title: 'Fluxnet',
        Content: 'Innovative Layer 2 scaling with Fluxnet',
        link: 'https://fluxnet.technology'
      },
      {
        title: 'Centralized Application',
        Catagory: 'Under Development',
        link: '/'
      }
    ]
  }
]

export const MarketCoins = [
  {
    image: Kucoin,
    text: 'KuCoin',
    url: 'https://www.kucoin.com/trade/LAYER-USDT?rcode=e21sNJ'
  },
  {
    image: Bitmart,
    text: 'BitMart',
    url: 'https://www.bitmart.com/trade/en-US?layout=basic'
  },
  {
    image: Latoken,
    text: 'Latoken',
    url: 'https://latoken.com/exchange/USDT-LAYER?r=8x59d2h3'
  },
  {
    image: GateIo,
    text: 'Gate.io',
    url: 'https://www.gate.io/trade/LAYER_USDT?ref=3018394'
  },
  {
    image: Pancakeswap,
    text: 'PancakeSwap',
    url: 'https://pancakeswap.finance/swap?inputCurrency=0xc2c23a86def9e9f5972a633b3d25f7ecbfa5e575&outputCurrency=wbnb'
  },
  {
    image: Kucoin,
    text: 'KuCoin',
    url: 'https://www.kucoin.com/trade/LAYER-BTC?rcode=e21sNJ'
  },
  {
    image: GateIo,
    text: 'Gate.io',
    url: 'https://www.gate.io/trade/LAYER_ETH?ref=3018394'
  },
  {
    image: Uniswap,
    text: 'Uniswap V2',
    url: 'https://app.uniswap.org/swap?inputCurrency=0x0ff6ffcfda92c53f615a4a75d982f399c989366b&outputCurrency=ETH'
  },
  {
    image: Kucoin,
    text: 'KuCoin',
    url: 'https://www.kucoin.com/trade/LAYER-USDT?rcode=e21sNJ'
  },
  {
    image: Bitmart,
    text: 'BitMart',
    url: 'https://www.bitmart.com/trade/en-US?layout=basic'
  },
  {
    image: Latoken,
    text: 'Latoken',
    url: 'https://latoken.com/exchange/USDT-LAYER?r=8x59d2h3'
  },
  {
    image: GateIo,
    text: 'Gate.io',
    url: 'https://www.gate.io/trade/LAYER_USDT?ref=3018394'
  },
  {
    image: Pancakeswap,
    text: 'PancakeSwap',
    url: 'https://pancakeswap.finance/swap?inputCurrency=0xc2c23a86def9e9f5972a633b3d25f7ecbfa5e575&outputCurrency=wbnb'
  },
  {
    image: Kucoin,
    text: 'KuCoin',
    url: 'https://www.kucoin.com/trade/LAYER-BTC?rcode=e21sNJ'
  },
  {
    image: GateIo,
    text: 'Gate.io',
    url: 'https://www.gate.io/trade/LAYER_ETH?ref=3018394'
  },
  {
    image: Uniswap,
    text: 'Uniswap V2',
    url: 'https://app.uniswap.org/swap?inputCurrency=0x0ff6ffcfda92c53f615a4a75d982f399c989366b&outputCurrency=ETH'
  }
]
