import React from 'react'
import Button from '../../../components/button'
import Arrow from '../../../assets/images/arrow-right.svg'
import HeroImg from '../../../assets/images/homehero.png'

function Hero() {
  return (
    <section className="banner">
      <div className="row">
        <div className="hero-content">
          <span className="sub-title">Unlock Your Crypto Potential with Unilayer </span>
          <h1 className="h1Heading">
            Experience lightning-fast trades on a secure and intuitive platform
          </h1>
          <p className="para">
            Take advantage of advanced tools and seize every opportunity in the crypto market with
            Unilayer, your one-stop DeFi powerhouse.
          </p>
          <Button url="https://unilayer.app/pro-mode/0xb1a824a6caf1f789aa7ca1072e36e83cd62ba3ee" title="Markets" bg="#D21C7C" color="#fff" icon={Arrow} />
        </div>
        <div className="hero-img">
          <img src={HeroImg} alt="Mobile" />
        </div>
      </div>
    </section>
  )
}

export default Hero
