import React from 'react'
import { Link } from 'react-router-dom'
import { FooterContent } from '../../constant'
import Logo from '../logo'

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="foot-logo">
            <Logo />
            <p>{FooterContent?.des}</p>
            <span>{FooterContent?.copyRight}</span>
          </div>
          {FooterContent?.FooterLinks.map((item, index) => {
            return (
              <div className="foot-links" key={index}>
                <h4>{item?.title}</h4>
                <ul>
                  {item?.Links.map((link, index) => {
                    return (
                      <li key={index}>
                        <Link to={link?.link || link?.url} target={`${link?.url && '_blank'}`}>
                          {link.image ? <img src={link.image} alt={link.text} /> : ''}
                          {link.image ? '' : <>{link.text}</>}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
        </div>
      </div>
    </footer>
  )
}

export default Footer
