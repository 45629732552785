import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { MarketCoins } from '../../../constant'
import Button from '../../../components/button'

function Market() {
  const [btnText, setBtnText] = useState('Copy')
  const textToCopy = '0x0ff6ffcfda92c53f615a4a75d982f399c989366b'

  const onCopy = () => {
    setBtnText(!btnText)
  }

  return (
    <section className="market-slide">
      <div className="container">
        <h2 className="h2heading">Buy Unilayer</h2>
        <span className="sub-title">Unilayer Smart Contract</span>
        <div className="flex">
          <p className="para">0x0ff6ffcfda92c53f615a4a75d982f399c989366b </p>
          <CopyToClipboard text={textToCopy} onCopy={onCopy}>
            <Button title={btnText ? 'Copy' : 'Copied'} bg="#0000" color="#D21C7C" />
          </CopyToClipboard>
        </div>
      </div>
      <Swiper
        spaceBetween={50}
        slidesPerView={7}
        loop={true}
        modules={[Autoplay]}
        autoplay={{
          delay: 2000
        }}
        breakpoints={{
          320: {
            slidesPerView: 2
          },
          768: {
            slidesPerView: 4
          },
          1023: {
            slidesPerView: 5,
            spaceBetween: 28
          },
          1366: {
            slidesPerView: 7,
            spaceBetween: 32
          }
        }}>
        {MarketCoins.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="market-card">
                <a href={item.url} target="_blank" rel="noreferrer">
                  <div className="img">
                    <img src={item.image} alt={item.text} />
                  </div>
                  <h2 className="h3heading">{item.text}</h2>
                </a>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </section>
  )
}

export default Market
