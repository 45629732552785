import React from 'react'
import { Sparklines, SparklinesLine } from 'react-sparklines';

function CryptoCard({ item }) {
  var percent24h = String(item?.percent24h).charAt(0)
  var percent7d = String(item?.percent7d).charAt(0)
  return (
    <div className="crypto-card">
    <div className="card-img">
      <img  style={{maxWidth:42}} src={item?.coinImage} alt="Coins" />
      <Sparklines style={{Width:50,height:50}}  data={item?.quotes}>
    <SparklinesLine color={percent7d === "-" ? '#EF4444' : '#22C55E'}/>
  </Sparklines>
    </div>
    <div className="card-content">
      <h6>
        {item?.title}
        <span>{item?.currency}</span>
      </h6>
      <div className="price">
        <span>${item?.price.toFixed(2)}</span>
        <p style={{ color: percent24h === "-" ? '#EF4444' : '#22C55E' }}>{item?.percent24h.toFixed(2)}%</p>
      </div>
    </div>
  </div>
  )
}

export default CryptoCard
