import React from 'react'
import Button from '../../../components/button'

function CtaSection() {
  return (
    <section className="Cta-Section">
      <div className="container">
        <h2 className="h2heading">Trade with Unilayer</h2>
        <Button
          url="https://unilayer.app/pro-mode/0xb1a824a6caf1f789aa7ca1072e36e83cd62ba3ee"
          title="Markets"
          bg="#17181A"
          color="#F5F5F5"
          border=" 1px solid #323538"
        />
      </div>
    </section>
  )
}

export default CtaSection
