import React from 'react'
import ServiceCard from '../../../components/ServicesCard'
import { cryptoExchange } from '../../../constant'

function Steps() {
  return (
    <section className="steps">
      <div className="container">
        <span className="sub-title">{cryptoExchange.subtitle}</span>
        <h2 className="h2heading">{cryptoExchange.title}</h2>
        <h2 className="h2heading">{cryptoExchange.title2}</h2>
        <div className="row">
          {cryptoExchange.steps.map((step, index) => {
            return <ServiceCard key={index} item={step} />
          })}
        </div>
      </div>
    </section>
  )
}

export default Steps
