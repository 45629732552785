import React from 'react'
import { Link } from 'react-router-dom'
// import { MegaMenuList } from '../../constant'

const MegaMenu = ({ data, handleClick }) => {
  return (
    <div className="mega-menu" 
    onMouseLeave={() => handleClick()}
    >
      {data?.map((item, index) => {
        return (
          <div className="row" key={index}>
            <div className="col4">
              <h2 class="h2heading">{item.title}</h2>
            </div>
            <div className="col8">
              <ul>
                {item.menus.map((list, index) => {
                  return (
                    <li>
                      <div className="mega-product">
                        <h2>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={list.link}
                            style={{ pointerEvents: list.link === '/' && 'none' }}>
                            {list.title}
                          </a>
                        </h2>

                        {list.Catagory && (
                          <span style={{ backgroundColor: list.comingSoon }}>{list.Catagory}</span>
                        )}
                        <p>{list.Content}</p>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default MegaMenu
