import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Error from '../screens/Error';
import Home from '../screens/Home';

const Routes = () => {
  return useRoutes([
    {
      path: '/',
      element: <Home />
    },
    { path: '404', element: <Error /> },
    {
      path: '*',
      element: <Navigate to="/404" />
    }
  ]);
};

export default Routes;
