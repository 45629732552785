import React from 'react'
import Button from '../button'

function ServiceCard({ item }) {
  return (
    <div className={`service-card ${item?.className ? item?.className : ''}`}>
      <div className="sec-cion">
        <img src={item?.image} alt={item?.title} />
      </div>
      <div className="sec-content">
        {item?.subTitle && <span className="sub-title">{item?.subTitle}</span>}
        <h3 className="h3heading">{item?.title}</h3>
        <p className="para center">{item?.desc}</p>
        {item?.btnText && (
          <Button url={item?.btnLink} title={item?.btnText} bg="#FAE8F2" color="#D21C7C" />
        )}
      </div>
    </div>
  )
}

export default ServiceCard
