import React, { useEffect, useState } from 'react'
import Button from '../button'
import SelectBox from '../CurrencySelect'
import Logo from '../logo'
import Nav from './nav'
import Toggle from '../../assets/images/toggle.svg'
import Close from '../../assets/images/close.svg'
import Gwei from '../../assets/images/Gwei.svg'

function Header() {
  const [isMobile, setIsMobile] = useState(false)
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (window.innerWidth <= 1023) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
    window.addEventListener('resize', (event) => {
      if (event.currentTarget.innerWidth > 1023) {
        setIsMobile(false)
      } else {
        setIsMobile(true)
      }
    })
  }, [])

  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="site-logo">
            <Logo />

            <div className={`navbar ${active ? 'active' : ''}`}>
              {isMobile && (
                <div className="site-logo">
                  <Logo />
                  <div className="toggle-icon" onClick={() => setActive(!active)}>
                    <img src={Close} alt="Close" />
                  </div>
                </div>
              )}
              <Nav isMobile={isMobile} />
              {/* {isMobile && (
                <div className="currency-select">
                  <div className="check-box">
                    <label>
                      <img src={Gwei} alt="34-Gwei" />
                      34 GWei
                    </label>
                  </div>
                  <SelectBox />
                  <Button url="/" title="Connect Wallet" bg="#D21C7C" color="#fff" />
                </div>
              )} */}
            </div>
          </div>
          {isMobile && (
            <div className="toggle-icon" onClick={() => setActive(!active)}>
              <img src={Toggle} alt="Humburger" />
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
