import React from 'react'
import { Sparklines, SparklinesLine } from 'react-sparklines'

function TradingTable({ header, data, loader, error }) {
  return (
    <div className="table">
      <div className="thead">
        <ul>
          <li>{header?.name}</li>
          <li>{header?.LastPrice}</li>
          <li>{header?.Changes}</li>
          <li>{header?.Volume}</li>
          <li>{header?.Chart}</li>
        </ul>
      </div>
      <div className="tbody">
      {loader ? (
          <p style={{ color: '#FFF' }}>Loading....</p>
        ) : error && !loader ? (
          <p style={{ color: '#FFF' }}>{error}</p>
        ) : (
          data !== undefined &&
          data?.length &&
          data?.map((item, index) => {
            const price = item.price.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
            const volume = item.volume.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })
            var percent24h = String(item?.percent24h).charAt(0)
            var percent7d = String(item?.percent7d).charAt(0)

            return (
              <ul key={index}>
                <li>
                  <img src={item?.coinImage} alt={item?.title} />
                  {item?.title}
                </li>
                <li>{item?.currency}</li>
                <li>${price}</li>
                <li style={{ color: percent24h === '-' ? '#EF4444' : '#22C55E' }}>
                  {item?.percent24h.toFixed(2)}%
                </li>
                <li>${volume}</li>
                <li>
                  <Sparklines data={item?.quotes}>
                    <SparklinesLine color={percent7d === '-' ? '#EF4444' : '#22C55E'} />
                  </Sparklines>
                </li>
              </ul>
            )
          })
        )}
      </div>
    </div>
  )
}

export default TradingTable
