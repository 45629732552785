import React from 'react'
import Sitelogo from '../../assets/images/Logo.svg'

function Logo() {
  return (
    <div className="site-logo">
      <img src={Sitelogo} alt="Site Logo" />
    </div>
  )
}

export default Logo
