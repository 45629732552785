import React from 'react'
import { Link } from 'react-router-dom'

function Button({ url, title, bg, color, onClick, icon, border }) {
  return (
    <>
      {!url ? (
        <button
          className="cta-btn"
          onClick={onClick}
          style={{ background: bg || '#000', color: color || '#fff', border: border || '' }}>
          {title}
          {icon && <img src={icon} alt="arrow" />}
        </button>
      ) : (
        <Link
          to={url}
          className="cta-btn"
          style={{ background: bg || '#000', color: color || '#fff', border: border || '' }}
          target="_blank"
          rel="noreferrer">
          {title}
          {icon && <img src={icon} alt="arrow" />}
        </Link>
      )}
    </>
  )
}

export default Button
