import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Arrow from '../../assets/images/arrow.svg'
import MegaMenu from './megaMenu'
import { MegaMenuList, mainMenu } from '../../constant'

function Nav({ isMobile }) {
  const [active, setActive] = useState(null)

  const handleClick = (id) => {
    if (id === active) {
      setActive(null)
    } else {
      setActive(id)
    }
  }
  return (
    <ul className="menu">
      {!isMobile ? (
        <>
          {mainMenu.map((item, index) => {
            return (
              <>
                <li className={`${item.MegaList && active === index ? 'active' : ''}`} key={index}>
                  <Link to={item.Link} onClick={() => item.MegaList && handleClick(index)}>
                    {item.title} {item.MegaList && <img src={Arrow} alt="Arrow" />}
                  </Link>
                  {item.MegaList && (
                    <>
                      {active === index && (
                        <MegaMenu handleClick={handleClick} data={item.MegaList} />
                      )}
                    </>
                  )}
                </li>
              </>
            )
          })}
        </>
      ) : (
        <>
          {MegaMenuList?.map((item, index) => {
            return (
              <li className={`${active === index ? 'active' : ''}`} key={index}>
                <Link to={item.Link} onClick={() => item.menus && handleClick(index)}>
                  {item.title} {item.menus && <img src={Arrow} alt="Arrow" />}
                </Link>
                {active === index && item.menus && (
                  <ul className="sub-menu" >
                    {item?.menus?.map((list, indx) => {
                      return (
                        <li key={indx}>
                          <div className="mega-product">
                            <h2>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href={list.link}
                                style={{ pointerEvents: list.link === '/' && 'none' }}>
                                {list.title}
                              </a>
                            </h2>
                            {list.Catagory && (
                              <span style={{ backgroundColor: list.comingSoon }}>
                                {list.Catagory}
                              </span>
                            )}
                            <p>{list.Content}</p>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                )}
                {/* {active ? (
                  <>
                    {item.MegaList.map((item, indx) => {
                      return (
                        <li
                          className={`${active ? 'active' : ''}`}
                          onClick={() => setActive(!active)}
                          key={indx}>
                          <Link to="/">
                            {item.title} <img src={Arrow} alt="Arrow" />
                          </Link>
                        
                        </li>
                      )
                    })}
                  </>
                ) : (
                  ''
                )} */}
              </li>
            )
          })}
        </>
      )}{' '}
    </ul>
  )
}

export default Nav
